<template>
  <div class="header" :style="{background:subjectColor}">
    <div class="logo">
      <img :src="logo" height="30" width="30" />
      <span>{{title}}</span>
    </div>
    <div class="collapse-btn" @click="handleCollapseChange">
      <i v-if="!collapse" class="el-icon-s-fold" style="color:#C0CBCC"></i>
      <i v-else class="el-icon-s-unfold" style="color:#C0CBCC"></i>
    </div>
    <div class="header-right">
      <div class="header-user-con">
        <!-- <div class="btn-fullscreen" @click="handleFullScreen">
          <el-tooltip effect="dark" :content="fullScreen?`取消全屏`:`全屏`" placement="bottom">
            <i class="el-icon-rank cblue"></i>
          </el-tooltip>
        </div> -->
        <div class="user-avator">
          <img :src="userInfo.avatarUrl" />
        </div>
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link" style="color:rgba(0, 0, 0, 0.65);">
            {{userInfo.userName + '-' }}{{ userInfo.roleId ? 'boss' : '成员'}}
            <i class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">

            <el-dropdown-item divided command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../../service/bus";
import utils from "../../utils/index";
import {
  queryOemDomain
} from "@/api/oemConfig.js";

export default {
  name: "commonHeader",
  data() {
    return {
      collapse: false,
      fullScreen: false,
      userInfo: this.$store.state.loginRoot.userInfo,
      title:'',
      logo:'',
      icon:''
    };
  },
  async created(){
    if(document.domain != "localhost" && document.domain != "crm.youdomain.com"){
      const oemConfig = await queryOemDomain({oemDomain:document.domain});
      if(!oemConfig){
        this.$message.error('oem配置错误,请联系售后');
        return;
      }
      this.setOemConfig(oemConfig);
    }else{
      this.setOemConfig();
    }
    console.log(this.$store.state.loginRoot)
  },
  computed:{
    // 主题颜色
    subjectColor(){
      return this.$store.state.subjectColor
    }
  },
  methods: {
    setOemConfig(oemConfig){
      console.log(oemConfig)
      //查询所有Oem配置 crm.youdomain.com localhost 本地调试
      console.log("domain:",document.domain);
      //获取icon
      var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      if(oemConfig){
        link.href = oemConfig.data.iconLogo;
        document.getElementsByTagName('head')[0].appendChild(link);
        //获取网页标题
        document.title = oemConfig.data.oemName;
        this.title = oemConfig.data.oemName;
        this.logo = oemConfig.data.oemLogo;
      }else{
        link.href = "http://ma-oos.oss-cn-beijing.aliyuncs.com/one-stand/icon/favicon.ico";
        document.getElementsByTagName('head')[0].appendChild(link);
        //获取网页标题
        document.title = "脉阿智能CRM";
        this.title = "脉阿智能CRM";
        this.logo = "http://ma-oos.oss-cn-beijing.aliyuncs.com/one-stand/icon/yimai.png";
      }
    },
    // 全屏
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      }
      this.fullScreen = !this.fullScreen;
    },

    // 下拉菜单选择
    handleCommand(commond) {
      if (commond === "logout") {
        utils.clearLoginInfo()
      }
    },

    // 控制折叠面板
    handleCollapseChange() {
      this.collapse = !this.collapse;
      bus.$emit("collapse", this.collapse);
    },
  },
};
</script>

<style scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height:48px;
  font-size: 22px;
  background-color: #6190e8;
  color: #ffffff;
  box-shadow: 0px 2px 6px rgba(115, 127, 128, 0.12);
  z-index: 2;
  /* border-bottom: 1px solid #eee; */
}
.collapse-btn {
  float: left;
  padding: 0 21px;
  cursor: pointer;
  line-height: 48px;
  color: #ffffff;
  margin-left: 23px;
}
.header .logo {
  float: left;
  line-height: 48px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ffffff;
  margin-left: 25px;
}
.header .logo img{
  vertical-align: middle;
  margin-right: 8px;
}
.header .logo span{
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 700;
  color: #51CBCD;
}
.header-right {
  float: right;
  padding-right: 20px;
}
.header-user-con {
  display: flex;
  height: 48px;
  align-items: center;
}
.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}
.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #999;
}
.btn-bell .el-icon-bell {
  color: #999;
}
.user-name {
  margin-left: 10px;
}
.user-avator {
  margin-left: 10px;
}
.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.el-dropdown-link {
  color: #ffffff;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.el-dropdown-menu__item {
  text-align: center;
}
</style>
